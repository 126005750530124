<template>
    <h1>{{ msg }}</h1>
</template>

<script>
import { cacheDelUser } from '../tools/tool'
export default {
    name: "SignOutView",
    data() {
        return {
            msg: "正在退出 ... "
        }
    },
    created() {
        this.$confirm("确认退出吗?").then(() => {
            cacheDelUser()
            this.$router.push({
                path: "/login",
            })
        })
    }
}
</script>

<style>
</style>